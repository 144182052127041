import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { RenderSource } from '../../../../../../constants/renderSource';
import { isD2GSupported } from '../../../../../../helpers/downloadToGo/downloadToGo-helper';
import {
  getFeatureToggleD2G,
  offerLocationSelector,
  renderSourceSelector,
} from '../../../../../../store/slices/application-selectors';
import D2G from '../../../ActionLayout/SecondaryActions/D2G/D2G';

import { useInvariantSelector } from '../../../../../../helpers/hooks/useInvariantSelector';
import { useIsTvDevice } from '../../../../../../helpers/hooks/useIsTvDevice';
import styles from './EpisodeEditorial.css';

const cx = classNames.bind(styles);

/**
 * Props for the EpisodeEditorial component.
 */
export type EpisodeEditorialProps = {
  /**
   * Label for the availability end date.
   */
  availabilityEndDateLabel?: string;
  /**
   * Unique identifier for the episode.
   */
  contentID?: string;
  /**
   * Broadcast date of the Episode. From Primary Action
   */
  description?: string;
  /**
   * Duration of the episode.
   */
  durationLabel?: string;
  /**
   * Title of the episode.
   */
  editorialTitle?: string;
  /**
   * Whether the episode is available for download to go.
   */
  hasD2G?: boolean;
  /**
   * Subtype of the episode.
   */
  subtype?: string;
  /**
   * Summary of the episode.
   */
  summary?: string;
  /**
   * Title of the episode.
   */
  title?: string;
};

/**
 * EpisodeEditorial component. Contains the editorial information for an episode.
 */
export default function EpisodeEditorial({
  availabilityEndDateLabel,
  contentID,
  description: broadcastDateLabel,
  durationLabel,
  editorialTitle,
  hasD2G,
  subtype,
  summary,
  title,
}: EpisodeEditorialProps): JSX.Element | null {
  const offerLocation = useInvariantSelector(offerLocationSelector);
  const isFeatureToggledD2G = useInvariantSelector(getFeatureToggleD2G);
  const isClient = useSelector(renderSourceSelector) === RenderSource.CLIENT;
  const isTvDevice = useIsTvDevice();

  const showD2G =
    isClient &&
    !!contentID &&
    isD2GSupported(offerLocation) &&
    isFeatureToggledD2G &&
    !!hasD2G;

  return (
    <>
      {(title || durationLabel || broadcastDateLabel) && (
        <div className={cx('episode-editorial__header')}>
          {title && (
            <h3
              className={cx('episode-editorial__title')}
              id={
                // used to aria-labelledby the button/link in Episode.tsx
                `episode-${contentID}-title-id`
              }
            >
              {title}
            </h3>
          )}
          {durationLabel && (
            <div className={cx('episode-editorial__duration')}>
              {durationLabel}
            </div>
          )}
          {broadcastDateLabel && (
            <p
              className={cx('episode-editorial__broadcast-date-label', {
                [`episode-editorial__broadcast-date-label--${subtype}`]:
                  subtype,
              })}
            >
              {broadcastDateLabel}
            </p>
          )}
        </div>
      )}
      {showD2G && (
        <div className={cx('episode-editorial__D2G')}>
          <D2G
            contentID={contentID}
            showLabel={false}
            isTvDevice={isTvDevice}
          />
        </div>
      )}
      {(editorialTitle || summary || availabilityEndDateLabel) && (
        <div className={cx('episode-editorial__body')}>
          {editorialTitle && (
            <div className={cx('episode-editorial__editorial-title')}>
              {editorialTitle}
            </div>
          )}
          {summary && (
            <p className={cx('episode-editorial__summary')}>{summary}</p>
          )}
          {availabilityEndDateLabel && (
            <div
              className={cx('episode-editorial__availability-end-date-label')}
            >
              {availabilityEndDateLabel}
            </div>
          )}
        </div>
      )}
    </>
  );
}
