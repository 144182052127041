import { ApiV2Episodes } from '@dce-front/hodor-types/api/v2/episodes/definitions';
import { useSelector } from 'react-redux';
import { MetaTagsTemplate } from '../../../../../../components/MetaTags/MetaTagsTemplate';
import Spinner from '../../../../../../components/Spinner/Spinner';
import { QueryKeys } from '../../../../../../constants/queryKeys';
import { useQueryTemplate } from '../../../../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import {
  FetchDetails,
  FetchRequestTypes,
} from '../../../../../../services/types';
import { profileIdSelector } from '../../../../../../store/slices/user-selectors';
import EpisodesList from '../../EpisodesList';

export type EpisodesListProps = {
  url: string;
  disableMetaUpdate?: boolean;
  onClickParameters?: FetchDetails['onClickParameters'];
};

function EpisodesListContainer({
  disableMetaUpdate = false,
  onClickParameters,
  url,
}: EpisodesListProps): JSX.Element | null {
  const profileId: number | undefined = useSelector(profileIdSelector);

  const [{ isLoading, data }] = useQueryTemplate<ApiV2Episodes>(url, {
    options: { profileId, queryKeyPrefix: QueryKeys.DetailEpisodesList },
    onClickParameters,
    template: FetchRequestTypes.EpisodeListContainer,
  });
  const { currentPage, episodes, selector } = data || {};

  if (isLoading) {
    return <Spinner />;
  }

  return currentPage && episodes ? (
    <>
      <MetaTagsTemplate data={data} disableMetaUpdate={disableMetaUpdate} />
      <EpisodesList
        currentPage={currentPage}
        episodes={episodes}
        onClickParameters={onClickParameters}
        seasonsSelector={selector}
        URLPage={url}
      />
    </>
  ) : null;
}

export default EpisodesListContainer;
